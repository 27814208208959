<template>
  <v-dialog
    transition="scale-transition"
    :max-width="500"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <o-form
        ref="form"
        @submit="submit"
        @close="$emit('input', false)"
      >
        <v-card-title class="primary--text d-flex justify-space-between">
          <span>{{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.sensorFormModal.title.' + mode) }}</span>
          <v-btn
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <o-text-field
                v-model="form.name"
                :rules="validation.name"
                label="labels.sensor.name"
                prepend-icon="mdi-rename-box"
                dense
                autofocus
                :counter="255"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <o-textarea
                v-model="form.description"
                :rules="validation.description"
                label="labels.sensor.description"
                prepend-icon="mdi-information"
                rows="1"
                dense
                :counter="255"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <o-text-field
                v-model.number="form.inputNumber"
                :rules="validation.inputNumber"
                label="labels.sensor.inputNumber"
                prepend-icon="mdi-ethernet-cable"
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <o-select
                v-model="form.type"
                :items="getSensorTypes()"
                :rules="validation.type"
                label="labels.sensor.type"
                prepend-icon="mdi-wifi-cog"
                dense
              >
                <template v-slot:item="props">
                  {{ $t('constants.sensor.type.' + props.item) }}
                </template>
                <template v-slot:selection="props">
                  {{ $t('constants.sensor.type.' + props.item) }}
                </template>
              </o-select>
            </v-col>
          </v-row>
          <v-row v-if="isInputNumberAlreadyUsed">
            <v-col>
              <v-alert
                type="error"
              >
                {{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.sensorFormModal.inputNumberAlreadyUsed') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <o-submit v-if="!isInputNumberAlreadyUsed" />
            </v-col>
          </v-row>
        </v-card-text>
      </o-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { hydrate } from '@utils/form';
  import { getSensorTypes } from '@utils/sensor';

  export default {
    props: {
      sensor: {
        type: Object,
        default: null,
      },
      usedInputNumbers: {
        type: Array,
        default: () => ([]),
      },
    },

    data: function () {
      return {
        show: true,
        form: {
          name: '',
          description: '',
          inputNumber: '',
          type: '',
        },
        validation: require('@/validation/entities/sensor.json'),
        mode: 'create',
      };
    },

    computed: {
      isInputNumberAlreadyUsed () {
        if (this.form.inputNumber !== '' && this.form.inputNumber !== null) {
          return this.usedInputNumbers.includes(parseInt(this.form.inputNumber));
        }

        return false;
      },
    },

    created () {
      if (this.sensor !== null) {
        hydrate(this.form, this.sensor);
        if (undefined !== this.sensor.id && this.sensor.id !== null) {
          this.mode = 'update';
        }
      }
    },

    methods: {
      submit (scope) {
        this.$emit('submit', this.form);
      },
      close () {
        this.$refs.form.close();
      },
      getSensorTypes () {
        return getSensorTypes();
      },
    },
  };
</script>
